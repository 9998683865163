import "./index.scss";

$(".btn-lean_more").on("mouseenter", function () {
  $(this).addClass("is-focus-over");
  $(this).removeClass("is-focus-out");
});
$(".btn-lean_more").on("mouseleave", function () {
  $(this).addClass("is-focus-out");
  $(this).removeClass("is-focus-over");
});

[...$(".btn-black")].map((item) => {
  const current_width = $(item)[0].offsetWidth;
  $(item).css("width", `${current_width}px`);
});

[...$(".btn-yelow")].map((item) => {
  const current_width = $(item)[0].offsetWidth;
  $(item).css("width", `${current_width}px`);
});

// $.each(btn_black, function(index,value){
//     let current_width = $(value).offsetWidth;
//     $(value).css('width', `${current_width}px`);
// });
// console.log('btn_yelow: ', $(btn_yelow));
// $.each(btn_yelow, function(index,value){
//     let current_width = $(value)[0].offsetWidth;
//     $(value).css('width', `${current_width}px`);
// });

// for (let item of button) {
//     item.addEventListener('mouseenter', e=>{
//       cursor.classList.add('is-hover')
//       cursor.classList.remove('is-not_hover')
//     })
//     item.addEventListener('mouseleave', e=>{
//       cursor.classList.remove('is-hover')
//       cursor.classList.add('is-not_hover')
//     })
//   }
