import './index.scss';



setTimeout(()=>{
    $('body').removeClass('index_overflow');
    
},6900);



if ($(window).width() <= 992) {
    $('.scroll_btn').on('click', function() {
        var elementClick = $(this).attr("data-href");
        var destination = $(elementClick).offset().top;
        $('html, body').animate({ scrollTop: destination }, 600);
        return false;     
    })
}
