import './index.scss';
import * as THREE from 'three';
//import * as dat from 'dat.gui';
// import * as OrbitControls from 'three-orbitcontrols';
import { OBJLoader } from './objLoader';


function randomInteger(min, max) {
    // получить случайное число от (min-0.5) до (max+0.5)
    let rand = min - 0.5 + Math.random() * (max - min + 1);
    return rand;
}

var mouse = {
    x: 0,
    y: 0
};

// function onMouseMove() {
//     if ($('#section_01').hasClass('active')) {

//         let procentWidth = document.documentElement.clientWidth / 100;
//         let procentHeight = document.documentElement.clientHeight / 100;

//         let x = Math.floor(e.offsetX / procentWidth);
//         let y = Math.floor(e.offsetY / procentHeight);

//         console.log('x: ', x);
//         console.log('y: ', y);
//     }
// };

// Follows the mouse event
function onMouseMove(event, solarSystem) {
    console.log('event: ', event);
    console.log('solarSystem: ', solarSystem);
    // Update the mouse variable
    event.preventDefault();
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    console.log('mouse: ', mouse);
    // Make the sphere follow the mouse
    // var vector = new THREE.Vector3(mouse.x, mouse.y, 0.5);
    // vector.unproject(camera);
    // var dir = vector.sub(camera.position).normalize();
    // var distance = -camera.position.z / dir.z;
    // var pos = camera.position.clone().add(dir.multiplyScalar(distance));
    // //mouseMesh.position.copy(pos);

    // light.position.copy(new THREE.Vector3(pos.x, pos.y, pos.z + 2));
};

class ColorGUIHelper {
    constructor(object, prop) {
        this.object = object;
        this.prop = prop;
    }
    get value() {
        return `#${this.object[this.prop].getHexString()}`;
    }
    set value(hexString) {
        this.object[this.prop].set(hexString);
    }
}

function main() {

    const canvas = document.querySelector('#c');
    const renderer = new THREE.WebGLRenderer({ canvas });
    const camera = new THREE.PerspectiveCamera(45, 2, 0.1, 1000);
    // const gui = new dat.GUI();
    const loader = new THREE.TextureLoader();
    camera.position.set(0, 4, 45);
    const scene = new THREE.Scene();
    scene.background = new THREE.Color('#1d1c19');
    let planet_loaded = false;

    //const controls = new OrbitControls(camera, canvas);
    const SW_planet = '/static/source/img/planet/test.obj';
    // controls.target.set(0, 5, 0);
    // controls.update();




    // materials 
    const meteorMaterialPositive = new THREE.MeshPhongMaterial({ color: '#ffe834' });
    const meteorMaterialNegative = new THREE.MeshPhongMaterial({ color: '#232217' });
    const SWMaterial = new THREE.MeshPhysicalMaterial({
        color: '#ababab',
        metalness: 0.69,
        clearcoat: 1.2
    });
    const farMeteorMaterial = new THREE.MeshPhongMaterial({
        color: '#ccc',
    });
    const coreMaterial = new THREE.MeshBasicMaterial({ color: '#967738' });
    const sunMaterial = new THREE.MeshPhongMaterial({ color: '#f49c49' });
    var materialBg = new THREE.MeshBasicMaterial({
        transparent: true
    })
    // materials 


    // gui.add(SWMaterial, 'metalness', 0, 1);
    // gui.add(SWMaterial, 'clearcoat', 0, 4);


    {
        const color = '#fff';
        const intensity = 0.56;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(41, 17.3, 34);
        light.target.position.set(45, 0, 18.8);
        scene.add(light);
        scene.add(light.target);


        // const helper = new THREE.DirectionalLightHelper(light);
        // scene.add(helper);

        // function updateLight() {
        //     light.target.updateMatrixWorld();
        //     helper.update();
        // }
        // updateLight();

        // gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
        // gui.add(light, 'intensity', 0, 2, 0.01);

        // makeXYZGUI(gui, light.position, 'position', updateLight);
        // makeXYZGUI(gui, light.target.position, 'target', updateLight);
    }
    class DegRadHelper {
        constructor(obj, prop) {
            this.obj = obj;
            this.prop = prop;
        }
        get value() {
            return THREE.MathUtils.radToDeg(this.obj[this.prop]);
        }
        set value(v) {
            this.obj[this.prop] = THREE.MathUtils.degToRad(v);
        }
    }

    {
        // const color = '#fff';
        // const intensity = 2;
        // const light = new THREE.SpotLight(color, intensity);
        // light.position.set(8.9, 23, 34);
        // light.angle = 16;
        // light.distance = 63;
        // light.penumbra = 0.6;
        // light.target.position.set(24, 2, -7);
        // scene.add(light);
        // scene.add(light.target);

        // const helper = new THREE.SpotLightHelper(light);
        // scene.add(helper);

        // function updateLight() {
        //     light.target.updateMatrixWorld();
        //     helper.update();
        // }
        // updateLight();


        // gui.addColor(new ColorGUIHelper(light, 'color'), 'value').name('color');
        // gui.add(light, 'intensity', 0, 2, 0.01);
        // gui.add(light, 'distance', 0, 100).onChange(updateLight);
        // gui.add(new DegRadHelper(light, 'angle'), 'value', 0, 90).name('angle').onChange(updateLight);
        // gui.add(light, 'penumbra', 0, 1, 0.01);

        // makeXYZGUI(gui, light.position, 'position', updateLight);
        // makeXYZGUI(gui, light.target.position, 'target', updateLight);
    }



    function makeXYZGUI(gui, vector3, name, onChangeFn) {
        const folder = gui.addFolder(name);
        folder.add(vector3, 'x', -50, 50).onChange(onChangeFn);
        folder.add(vector3, 'y', 0, 50).onChange(onChangeFn);
        folder.add(vector3, 'z', -50, 50).onChange(onChangeFn);
        folder.open();
    }

    {
        const color = '#fff';
        const intensity = 2;
        const light = new THREE.PointLight(color, intensity);
        light.position.set(22, 7, -4.3);
        light.distance = 6;
        scene.add(light);
    }

    {
        const color = '#fff';
        const intensity = 2;
        const light = new THREE.PointLight(color, intensity);
        light.position.set(18, 4.4, 4.5);
        light.distance = 6.3;
        scene.add(light);

    }
    {
        const color = '#fff';
        const intensity = 2;
        const light = new THREE.PointLight(color, intensity);
        light.position.set(17, 4, 10);
        light.distance = 10;
        scene.add(light);


    }




    // использовать только одну сферу для всего
    const radius = 1;
    const widthSegments = 50;
    const heightSegments = 50;
    const sphereGeometry = new THREE.SphereGeometry(
        radius, widthSegments, heightSegments);


    const solarSystem = new THREE.Object3D();
    solarSystem.rotation.y = .6;
    solarSystem.rotation.z = .4;
    solarSystem.rotation.x = -.1;
    scene.add(solarSystem);

    const meteorPositiveOrbit = new THREE.Object3D();
    solarSystem.add(meteorPositiveOrbit);

    const meteorNegativeOrbit = new THREE.Object3D();
    solarSystem.add(meteorNegativeOrbit);

    const farMeteorOrbit = new THREE.Object3D();
    solarSystem.add(farMeteorOrbit);

    const starwayOrbit = new THREE.Object3D();
    solarSystem.position.y = 5;
    solarSystem.position.x = 20;
    starwayOrbit.scale.set(0, 0, 0);
    solarSystem.add(starwayOrbit);

    // createPlanet();


    const meteorMaterials = [meteorMaterialPositive, meteorMaterialNegative, farMeteorMaterial];



    const sunMesh = new THREE.Mesh(sphereGeometry, sunMaterial);
    sunMesh.scale.set(0, 0, 0);
    starwayOrbit.add(sunMesh);

    {
        let threeD = new OBJLoader();
        threeD.load(SW_planet, function (object) {

            const materials = {
                default: SWMaterial,
            };
            object.traverse(node => {
                const material = materials[node.material?.name];
                if (material) {
                    node.material = material;
                }
            })
            object.scale.set(4.5, 4.5, 4.5);
            starwayOrbit.add(object);
            planet_loaded = true;
        });
        threeD.load(SW_planet, function (object) {
            const materials = {
                default: coreMaterial,
            };
            object.traverse(node => {
                const material = materials[node.material?.name];
                if (material) {
                    node.material = material;
                }
            })
            object.scale.set(1.4, 1.4, 1.4);
            starwayOrbit.add(object);
        });
    }

    const farMeteorArray = [{
        x: 40, // відстань від планети
        y: 5, // відстань верх/вниз
        z: 0, // відстань між метеоритами
        scale: [1, 1, 1], // розмір метеорита
        meteorMaterials: meteorMaterials[2]
    },
    {
        x: 45,
        y: 25,
        z: 50,
        scale: [1, 1, 1],
        meteorMaterials: meteorMaterials[2]
    },
    {
        x: 25,
        y: 30,
        z: 40,
        scale: [2, 2, 2],
        meteorMaterials: meteorMaterials[2]
    },
    {
        x: -80,
        y: 25,
        z: -20,
        scale: [2, 2, 2],
        meteorMaterials: meteorMaterials[2]
    },
    {
        x: -90,
        y: -10,
        z: 20,
        scale: [2, 2, 2],
        meteorMaterials: meteorMaterials[2]
    },
    {
        x: 10,
        y: -30,
        z: 40,
        scale: [1, 1, 1],
        meteorMaterials: meteorMaterials[2]
    },]

    const array = [];
    let R1 = 16;
    let R2 = 17;
    let R3 = 18;
    let R4 = 19;
    let R5 = 20;
    let R6 = 22;
    let R7 = 23;
    let num = 50;
    genCoordMeteor(R1, num, array);
    genCoordMeteor(R2, num, array);
    genCoordMeteor(R3, num, array);
    genCoordMeteor(R4, num, array);
    genCoordMeteor(R5, num, array);
    genCoordMeteor(R6, num, array);
    genCoordMeteor(R7, num, array);


    function genCoordMeteor(R, num, array) {
        let startCoordX = 0;
        let startCoordY = 0;
        let startCoordZ = 0;
        for (let i = 0; i <= num; i++) {
            let f = 2 / num * i * Math.PI;  // Рассчитываем угол каждой картинки в радианах
            let x = startCoordX + R * Math.sin(f);
            let y = startCoordY + randomInteger(-.6, .6);
            let z = startCoordZ + R * Math.cos(f);
            let material = Math.floor(Math.random() * 2);
            let size = Math.floor(Math.random() * 5);
            array.push({
                x, // вліво-вправо
                y, // верх-вниз
                z, // вперед-назад
                scale: [size * .1, size * .1, size * .1], // розмір метеорита
                meteorMaterials: meteorMaterials[material]
            })
        }
    }


    const meteorsPositive = createMeteor(array, meteorPositiveOrbit);
    // const meteorsNegative = createMeteor(meteorMaterialNegative, meteorNegativeArray, meteorNegativeOrbit);
    const farMeteors = createMeteor(farMeteorArray, farMeteorOrbit);

    // [{
    //     x: 10, // відстань від планети
    //     y: 0, // відстань верх/вниз
    //     z: 0, // відстань між метеоритами
    //     scale: [.3, .3, .3], // розмір метеорита
    // },
    // {
    //     x: 10,
    //     y: 0,
    //     z: 2,
    //     scale: [.3, .3, .3],
    // }]

    function genPositionMeteor(model) {

    };

    function createMeteor(arrayPos, orbit) {
        const arrayOfMeteors = [];
        arrayPos.forEach((obj) => {
            const meteor = new THREE.Mesh(sphereGeometry, obj.meteorMaterials);
            // x - відстань від основної планети
            // y - відстань верх або вниз
            // z - дальність шара один від одного
            meteor.position.set(obj.x, obj.y, obj.z);
            meteor.scale.set(obj.scale[0], obj.scale[1], obj.scale[2]);
            orbit.add(meteor);
            arrayOfMeteors.push(meteor);
        });

        return arrayOfMeteors;
    }


    // document.addEventListener('mousemove', function (event) {
    //     // Update the mouse variable
    //     event.preventDefault();
    //     mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    //     mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    //     let scale = ((event.clientX / window.innerWidth) + 1) / 1.3;

    //     meteorPositiveOrbit.rotation.z = mouse.y * 0.4
    //     meteorPositiveOrbit.rotation.y = mouse.x * 0.4
    //     meteorPositiveOrbit.scale.set(scale, scale, scale)

    // }, false);
    // meteorPositiveOrbit.rotation.z = .5 * 0.4
    // meteorPositiveOrbit.rotation.y = -3.5 * 0.4
    // meteorPositiveOrbit.scale.set(1.2, 1.2, 1.2)


    // create the geometry sphere
    var geometryBg = new THREE.SphereGeometry(100, 32, 32)
    // create the material, using a texture of startfield

    materialBg.map = THREE.ImageUtils.loadTexture('/static/source/img/planet/bg.png')
    materialBg.side = THREE.BackSide
    // create the mesh based on geometry and material
    var meshBg = new THREE.Mesh(geometryBg, materialBg)
    scene.add(meshBg);


    requestAnimationFrame(render);
    function render(time) {
        time *= 0.0001;  // конвертировать время в секунды
        if (resizeRendererToDisplaySize(renderer)) {
            const canvas = renderer.domElement;
            camera.aspect = canvas.clientWidth / canvas.clientHeight;
            camera.updateProjectionMatrix();
        }

        meshBg.rotation.y = time * .1;
        starwayOrbit.rotation.y = time;
        farMeteorOrbit.rotation.y = - time * .5;
        meteorPositiveOrbit.rotation.y = - time;

        // $('#c').on('mousemove', mouse_move);

        if (planet_loaded) {
            let speed = 0.1;
            let scaleOrbit = starwayOrbit.scale.x;
            console.log('scaleOrbit: ', scaleOrbit);

            if (scaleOrbit < 1) {
                starwayOrbit.scale.x += speed;
                starwayOrbit.scale.y += speed;
                starwayOrbit.scale.z += speed;

                sunMesh.scale.x += speed + 0.08;
                sunMesh.scale.y += speed + 0.08;
                sunMesh.scale.z += speed + 0.08;

            } else if (scaleOrbit >= 1) {
                planet_loaded = false;
            }
        }


        renderer.render(scene, camera);
        requestAnimationFrame(render);
    }


    function mouse_move(e) {
        let x = e.clientX;
        let y = e.clientY;
        let time = 10;

        meteorsPositive.forEach((meteor, ndx) => {
            const speed = .2 + ndx * .1;
            const rot = time * speed;
            meteor.position.x = rot;
            meteor.position.y = rot;
        });

    }

    function resizeRendererToDisplaySize(renderer) {
        const canvas = renderer.domElement;
        const pixelRatio = window.devicePixelRatio;
        const width = canvas.clientWidth * pixelRatio | 0;
        const height = canvas.clientHeight * pixelRatio | 0;
        const needResize = canvas.width !== width || canvas.height !== height;
        if (needResize) {
            renderer.setSize(width, height, false);
        }
        return needResize;
    }

    function createPlanet() {
        const sunMaterial = new THREE.MeshPhongMaterial({ color: '#e7aa43' });
        const sunMesh = new THREE.Mesh(sphereGeometry, sunMaterial);
        sunMesh.scale.set(0, 0, 0);
        starwayOrbit.add(sunMesh);

        // const swMaterial = new THREE.MeshPhongMaterial({
        //     map: SW_planet,
        //     side: THREE.BackSide,
        //     transparent: true,
        // });

        // const swMesh = new THREE.Mesh(sphereGeometry, swMaterial);
        // swMesh.scale.set(8, 8, 8);
        // starwayOrbit.add(swMesh);

        // const swMaterialFront = new THREE.MeshPhongMaterial({
        //     map: SW_planet,
        //     side: THREE.FrontSide,
        //     transparent: true,
        // });

        // const swMeshFront = new THREE.Mesh(sphereGeometry, swMaterialFront);
        // swMeshFront.scale.set(8, 8, 8);
        // starwayOrbit.add(swMeshFront);
    }

}


let widthDevice = document.documentElement.clientWidth;
if (widthDevice >= 992) {
    main();
} else {

}
