import "./index.scss";
import { check_operator } from "./../mob_operator/index";
import { BitrixIntegration } from "../BitrixIntegrations/bitrixIntegrations";
// import intlTelInput from 'intl-tel-input';

let array_oper_number = [
  39,
  50,
  63,
  66,
  67,
  68,
  73,
  89,
  91,
  92,
  93,
  94,
  95,
  96,
  97,
  98,
  99,
  44,
];
var array_error;

// international
let check_ukraine = true;
var input = document.querySelectorAll('input[type="tel"]');
input.forEach((input) => {
  var iti = intlTelInput(input, {
    nationalMode: false,
    customPlaceholder: function (
      selectedCountryPlaceholder,
      selectedCountryData
    ) {
      let mass_numbers = selectedCountryPlaceholder
        .slice(1)
        .replace(new RegExp("[0-9]", "g"), "9");
      let current_mask = `+${selectedCountryData.dialCode} ${mass_numbers}`;

      let current_input = $('input[type="tel"]');
      $(current_input).attr("current_mask", current_mask);
      $(current_input).attr("current_country", selectedCountryData.iso2);

      return "";
    },
  });

  input.addEventListener("countrychange", function (e) {
    reload_mask();
  });

  let language = find_lang_site();

  setTimeout(() => {
    $('input[type="tel"]').mask("+38(099) 999-9999");
  }, 200);

  if (language == "UA") {
    iti.setCountry("ua");
  } else if (language == "EN") {
    iti.setCountry("us");
    reload_mask();
  } else {
    iti.setCountry("ua");
    reload_mask();
  }
});

function find_lang_site() {
  let current_lang = $(".main__foter_lang-link-active")
    .text()
    .replace(/\s+/g, " ")
    .trim();
  return current_lang;
}

function reload_mask() {
  console.log("done!");
  let current_input = $('input[type="tel"]');

  if ($(current_input).attr("current_country") == "ua") {
    check_ukraine = true;

    setTimeout(() => {
      $('input[type="tel"]').mask("+38(099) 999-9999");
    }, 200);
    array_error = check_operator(array_oper_number, "true");
  } else {
    check_ukraine = false;
    array_error = check_operator(array_oper_number, "false");

    setTimeout(() => {
      $('input[type="tel"]').mask($(current_input).attr("current_mask"));
    }, 200);
  }
}

function create_search_country() {
  let all_phone_input = $(".iti__country-list");
  $(".search_by_country").remove();
  $(all_phone_input)[0].prepend(create_search_input());
}
function create_search_input() {
  let search_input = document.createElement("input");
  search_input.classList.add("search_by_country");
  search_input.setAttribute(`placeholder`, "Введіть країну");

  return search_input;
}

// international

$(function () {
  Onload();

  $(".iti__flag-container").on("click", function () {
    create_search_country();
  });

  $("#order_one_click").on("click", function () {
    $.fancybox.open({
      src: "#order_one_click_form",

      afterShow: function (instance, current) {
        [...$(current.$content).find(".btn-black")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        [...$(current.$content).find(".btn-yelow")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        valide_form("#feedback_smal3", ".input", true);
      },
    });
  });

  $("#get_consultation").on("click", function () {
    $.fancybox.open({
      src: "#get_consultation_form",

      afterShow: function (instance, current) {
        [...$(current.$content).find(".btn-black")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        [...$(current.$content).find(".btn-yelow")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        valide_form("#feedback_smal2", ".input", true);
      },
    });
  });
  $(".order_service").on("click", function () {
    let packeg = $(this).data("packed");
    console.log("packeg");

    $.fancybox.open({
      src: "#order_service_small_form",

      afterShow: function (instance, current) {
        console.log("packeg", packeg);

        $(current.$content).find("#packed_value").val(packeg);

        [...$(current.$content).find(".btn-black")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        [...$(current.$content).find(".btn-yelow")].map((item) => {
          $(item).css("width", `auto`);
          const current_width = $(item)[0].offsetWidth;
          $(item).css("width", `${current_width}px`);
        });

        valide_form("#order_service_small_form1", ".input", true);
      },
    });
  });
});

/**
 * valide_form - Валідація форм
 * @param {selector form} ID Форми на яку підвішують валідацію
 * @param {class name} class групи куди виводять помилки
 * @param {bull} true Чи виводи вспливайку пісял відповіді ajax
 *
 **/

function Onload() {
  valide_form("#brief_st", ".input", false);
  valide_form("#contacts", ".input", true);
  valide_form("#feedback_smal", ".input", true);
  valide_form("#logo_form_2", ".input", true);
  // valide_form('#feedback_smal3', '.input', true);

  valide_form("#contacts", ".input", true);
  valide_form("#contact_seo", ".input", true);
}

function location_leng() {
  return location.pathname.split("/")[1];
}

export function valide_form(id_form, append_error_box, check_request) {
  var check_request = check_request;
  if ($(id_form).length > 0) {
    var lang_site;
    var errore_text = {};

    lang_site = location_leng();
    switch (lang_site) {
      case "uk":
        errore_text.required = "Поле обов'язково для заповнення";
        errore_text.email = "Поле має містити email";
        errore_text.rating = "Оцінка товару є обов'язкова";
        break;
      case "ru":
        errore_text.required = "Поле обязательно для заполнения";
        errore_text.email = "Поле должно содержать email";
        errore_text.rating = "Оценка товара является обязательная";

        break;
      case "en":
        errore_text.required = "The field is required";
        errore_text.email = "The field must contain an email";
        errore_text.rating = "Evaluation of the goods is required";

        break;
      default:
        errore_text.required = "Поле обов'язково для заповнення";
        errore_text.email = "Поле має містити email";
        errore_text.rating = "Оцінка товару є обов'язкова";
    }
    console.log("check_ukraine: ", check_ukraine);
    $(id_form).validate({
      errorPlacement: function (event, validator) {
        $(validator).parents(append_error_box).append($(event));
        $(validator).parents(append_error_box).addClass("is-error");
      },
      ignore: "not:hidden.product_rating",
      rules: {
        name: {
          required: true,
        },
        username: {
          required: true,
        },
        password: {
          required: true,
        },
        corporation_1: {
          required: true,
        },
        corporation_3: {
          required: true,
        },
        corporation_4: {
          required: true,
        },
        corporation_5: {
          required: true,
        },
        email: {
          required: true,
          email: true,
        },
        user_last_name: {
          required: true,
        },
        product_rating: {
          required: true,
        },
        payment: {
          required: true,
        },
        phone: {
          required: true,
          operator: true,
        },
        address: {
          required: true,
        },
        pass: {
          required: true,
        },
        repeat_pass: {
          required: true,
        },
        text: {
          required: true,
        },
        slogan: {
          required: true,
        },
        sphere_corporation: {
          required: true,
        },
        concurents: {
          required: true,
        },
        color_combination: {
          required: true,
        },
      },

      messages: {
        username: {
          required: errore_text.required,
        },
        password: {
          required: errore_text.required,
        },
        corporation_1: {
          required: errore_text.required,
        },
        corporation_3: {
          required: errore_text.required,
        },
        corporation_4: {
          required: errore_text.required,
        },
        corporation_5: {
          required: errore_text.required,
        },
        name: {
          required: errore_text.required,
          email: errore_text.email,
        },
        email: {
          required: errore_text.required,
          email: errore_text.email,
        },
        user_last_name: {
          required: errore_text.required,
        },
        payment: {
          required: errore_text.required,
        },
        product_rating: {
          required: errore_text.rating,
        },
        phone: {
          required: errore_text.required,
          operator: array_error.curr_text,
        },
        address: {
          required: errore_text.required,
        },
        pass: {
          required: errore_text.required,
        },
        repeat_pass: {
          required: errore_text.required,
        },
        text: {
          required: errore_text.required,
        },
        slogan: {
          required: errore_text.required,
        },
        sphere_corporation: {
          required: errore_text.required,
        },
        concurents: {
          required: errore_text.required,
        },
        color_combination: {
          required: errore_text.required,
        },
      },

      submitHandler: function (form) {
        event.preventDefault();

        // Закидує в скритий інпут інфу про ольорове оформлення логотипа
        let current_color_logo = $(".logo_color__wrap").find(".is-active");
        let current_color_mass = [];
        $.each($(current_color_logo), function (index, value) {
          current_color_mass.push($(value).attr("data-check"));
        });
        $(".logo_color").val(current_color_mass);

        let data = formSerialize({ form: form });

        send_form({ form: form, data: data });

        const all_form_input = form.querySelectorAll("input");
        // const all_form_textaria = form.querySelectorAll('textarea');

        // for (const key in all_form_input) {
        //   if (all_form_input.hasOwnProperty(key)) {
        //     const element = all_form_input[key];
        //   }
        // }

        function send_form(params) {
          let form = params.form;
          var check_id = $(form).attr("id");
          let form_data = params.data;
          let url_form = form.action;
          var form_json = {};

          if (url_form != "") {
            $(".loader_all").addClass("loader_all-active");
            var loader =
              '<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>';
            document.getElementById("loader_all__wrap").innerHTML = loader;

            fetch(url_form, {
              method: "POST",
              body: form_data,
            })
              .then((data) => {
                return data.json();
              })
              .then((data) => {
                $(".loader_all").removeClass("loader_all-active");
                if (
                  data.status == "OK" &&
                  typeof data["status"] !== "undefined"
                ) {
                  if (
                    typeof data["redirect_url"] !== "undefined" &&
                    data.redirect_url != ""
                  ) {
                    // Аналітика для двох видів форм feedback_smal і contacts
                    if (check_id == "feedback_smal" || check_id == "contacts") {
                      gtag_report_conversion();
                    }
                    // console.log('step 02');
                    let bitrixIntegrationForm = new BitrixIntegration(
                      check_id,
                      form_data,
                      form
                    );
                    // console.log('step 03');
                    bitrixIntegrationForm.run();
                    // console.log('step 04');
                    // Аналітика для двох видів форм feedback_smal і contacts

                    clear_input();
                    location.href = data.redirect_url;
                  } else {
                    // sayHi();
                  }
                }
              });
          } else {
            console.error(`URL Form is #${params.form.id} not found `);
          }
        }

        function clear_input() {
          const TYPE_INPUT = {
            TEXT: "text",
            EMAIL: "email",
            CHECKBOX: "checkbox",
            HIDDEN: "hidden",
            TEL: "tel",
            FILE: "file",
          };
          const all_form_input = form.querySelectorAll("input");
          const all_form_textaria = form.querySelectorAll("textarea");

          for (const key in all_form_textaria) {
            if (all_form_textaria.hasOwnProperty(key)) {
              const element = all_form_textaria[key];
              element.value = "";
            }
          }

          for (const key in all_form_input) {
            if (all_form_input.hasOwnProperty(key)) {
              const element = all_form_input[key];

              switch (element.type) {
                case TYPE_INPUT.TEXT:
                  element.value = "";
                  break;
                case TYPE_INPUT.EMAIL:
                  element.value = "";
                  break;
                case TYPE_INPUT.TEL:
                  element.value = "";
                  break;
                // case TYPE_INPUT.HIDDEN:
                //   element.value=''
                //   break;
                case TYPE_INPUT.FILE:
                  element.value = "";
                  break;

                case TYPE_INPUT.CHECKBOX:
                  $(element).prop("checked", false);
                  break;
              }
            }
          }
        }

        function formSerialize(params) {
          let form = params.form;
          let serialize = $(form).serializeArray();

          let data = {};
          (data["inputs"] = []),
            (data["item_category_ids"] = []),
            (data["item_ids"] = []),
            (data["inputs"] = []);

          let inputs_other = form.querySelectorAll("input[name=other]");
          Array.apply(null, inputs_other).map((item) => {
            let categories_input = $(item)
              .parents(".form_container__main")
              .find(".form_box__header-input");
            data["inputs"].push({
              item_category_id: categories_input[0].name,
              text: item.value,
            });
          });

          $(serialize).each(function (index, input) {
            if (input.value == "category") {
              data["item_category_ids"].push(input.name);
            } else if (input.value == "item") {
              data["item_ids"].push(input.name);
            } else {
              data[input.name] = input.value;
            }
          });

          let file_logo__life = form.querySelectorAll("#file_logo__life")[0];
          let file_logo__life2 = form.querySelectorAll("#last_hidden_file")[0];

          let Formdata = new FormData();
          if (file_logo__life != undefined) {
            if (file_logo__life.files[0] !== undefined) {
              Formdata.append("logo", file_logo__life.files[0]);
            }
          }
        
          // TODO: Написати костиль для останнього лого
          if (file_logo__life2 !== undefined) {
            if (file_logo__life2.files[0] !== undefined) {
              Formdata.append("logo2", file_logo__life2.files[0]);
            }
          }
        
          let current_texts_mass = [];
          let all_texts_logo = $(".logo_input__wrap");
          $.each($(all_texts_logo), function (index, value) {
            current_texts_mass.push({
              text_id: $(value).attr("data-text_id"),
              value: $(value).find(".input-field").val(),
            });
          });
          data["texts"] = current_texts_mass;

          let current_ranges_mass = [];
          let all_ranges_logo = $(".all_range_slider");
          $.each($(all_ranges_logo), function (index, value) {
            current_ranges_mass.push({
              range_id: $(value)
                .parents(".slider__wrap")
                .attr("data-range_item_id"),
              value: $(value).val(),
            });
          });
          data["ranges"] = current_ranges_mass;

          // let current_images_mass = [];

          // let all_imges_logo = $('.all_range_slider');
          // $.each($(all_imges_logo), function(index, value) {
          //   current_images_mass.push();
          // });

          // Закидує в скритий інпут інфу про тип логотипа
          let current_type_logo = $(".type_logo__block").find(".is-active");
          let current_type_mass = [];
          $.each($(current_type_logo), function (index, value) {
            current_type_mass.push($(value).attr("data-check"));
          });

          data["images"] = current_type_mass;
          // console.log('data: ', data);

          Formdata.append("data", JSON.stringify(data));

          return Formdata;
        }
      },
    });
  }
}


