import './index.scss'



$('.our_works_wrap').slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
    prevArrow: '<div class="slick-first"><</div>',
    nextArrow: '<div class="slick-second">></div>',
    lazyLoad: "ondemand",
    responsive: [
        {
            breakpoint: 775,
            settings: {
                slidesToShow: 1,
            }
        },
    ]
  });


  $('.slick-first').click(function () {
    $(".our_works_wrap").slick('slickPrev');
  });
  $('.slick-second').click(function () {
    $(".our_works_wrap").slick('slickNext');
  });

