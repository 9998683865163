// script interface
import './../components/common_componentc/normalize/index';
import './../components/interface/grid';
import './../components/interface/button/index';
import './../components/interface/form/index';
import './../components/interface/hamburgers/hamburgers.scss';

// script common elements
import './../components/common_componentc/header';
import '../components/common_componentc/footer';
import './../components/common_componentc/our_works_static/';
// import './../components/common_componentc/custom_cursor'
import './../components/common_componentc/editor_text';
import './../components/module/form_errors';

// script pages
import './../components/pages/index/all_page_script.js';
