import "regenerator-runtime/runtime";
import { addToCart } from "../../../api/basket";

const productsItems = document.querySelector(".items_form-bottom");
const addToCartBtn = document.querySelector(".addInCart");
const addToCartAllBtn = document.querySelector(".addAll");
const allProducts = document.querySelectorAll(".items_form-item");

// оновлення корзини в хедері
export const updateCartLabel = ({ cart_products }) => {
  const count = cart_products?.length;
  const headerCartTotalCount = document.querySelector(".cart-total-count");

  if (count && headerCartTotalCount) {
    headerCartTotalCount.textContent = count;
  } else {
    headerCartTotalCount.textContent = "";
  }
};

if (productsItems) {
  productsItems.addEventListener("click", ({ target }) => {
    const productItem = target.closest(".items_form-item");

    if (productItem) {
      productItem.classList.toggle("selected");
    }
  });
}

if (addToCartBtn) {
  addToCartBtn.addEventListener("click", () => {
    allProducts.forEach(async (product) => {
      if (product.classList.contains("selected")) {
        const data = await addToCart(product.dataset.product_id);

        updateCartLabel(data);
      }
    });
  });
}

if (addToCartAllBtn) {
  addToCartAllBtn.addEventListener("click", () => {
    allProducts.forEach(async (product) => {
      const data = await addToCart(product.dataset.product_id);

      updateCartLabel(data);
    });
  });
}
