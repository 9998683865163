import './index.scss';




$('.btn-drag').on('click', function () {

    var isSolid_work_slider = $(this).parents('.fp-tableCell').find('.box__main').find('.slider_work').length;
    if (isSolid_work_slider > 0) {
        setTimeout(sayHi, 1000);
    }
    function sayHi() {
        var slider_worc = $(".slider_work").slick({
            arrows: false,
            infinite: true,
            slidesToShow: 2,
            variableWidth: true,
            slidesToScroll: 1,
            swipe: true
        });
        slider_worc.on('beforeChange', function (event, slick, currentSlide, nextSlide) {

            if (currentSlide !== nextSlide) {
                $('.slick-track').toggleClass('slick-track-even');
            }


        });

    }

    //   


})



$('.box__main_item').on('click', event => {

    let service_category_id = event.currentTarget.dataset.service_category_id;

    if (service_category_id != undefined) {
        sessionStorage.setItem('service_category_id', service_category_id);
    }

})



$('.box__shifting').on('click', function () {
    box_open($(this))

})
$('.box__header_btn').on('click', function () {
    $(this).parents('.fp-tableCell').addClass('box-close').removeClass('box-open');
    event.stopPropagation()
})



function box_open(eletet) {

    $(eletet).parents('.fp-tableCell').addClass('box-open').removeClass('box-close');

    var isSolid_work_slider = $(eletet).parents('.fp-tableCell').find('.box__main').find('.slider_work')

    if (isSolid_work_slider.length > 0 & isSolid_work_slider.hasClass('slick-initialized') == false) {
        setTimeout(sayHi, 1000);
    }

    function sayHi() {


        // var slider_worc = $(".slider_work").slick({
        //     arrows: false,
        //     infinite: true,
        //     slidesToShow: 2,
        //     variableWidth: true,
        //     slidesToScroll:1,
        // });
        // let top_slider = $(".slider_work").offset().top ;

        // $('.work__control').css({ top : top_slider });

        // $(".slider__control_next").on('click', function() {
        //     slider_worc.slick('slickNext');
        // });
        // $(".slider__control_prew").on('click', function() {
        //     slider_worc.slick('slickPrev');
        // });

        // slider_worc.on('beforeChange', function(event, slick, currentSlide, nextSlide){

        //    if(currentSlide !== nextSlide){
        //     $('.slick-track').toggleClass('slick-track-even');
        //    }


        // });

    }
}



let btn_swipe = document.getElementsByClassName('swipe-group-btn')
for (const key in btn_swipe) {
    if (btn_swipe.hasOwnProperty(key)) {
        const btn = btn_swipe[key];
        btn.onmousedown = function (event) { // (1) отследить нажатие
            btn.classList.add('swipe_animation-click')

            let parent_wrap = btn.closest(".swipe")
            let width_btn = btn.offsetWidth
            let position_left_btn = $(this).offset().left;
            let position_click = event.pageX - position_left_btn;
            let control_click_corditate = event.clientX;
            let line = parent_wrap.querySelectorAll('.swipe-liner')[0]

            document.onmousemove = function (e) {
                parent_wrap.classList.remove('swipe_animation-start')
                var x = control_click_corditate - e.clientX;
                moveAt(x);
            };

            function moveAt(pageX) {
                if ((pageX <= 190 && pageX >= 0)) {
                    if (pageX > 40) {
                        finallAnimation();
                    } else {
                        btn.style.left = 190 - pageX + 'px';
                        line.style.width = 150 - pageX + 'px';
                    }

                }
            }

            document.onmouseup = function () {

                if (btn.offsetLeft < 150) {
                    finallAnimation();
                } else {
                    startAnimation();

                }
                document.onmousemove = null;
                btn.classList.remove('swipe_animation-click');
                btn.onmouseup = null;

            };


            btn.ondragstart = function () {
                return false;
            };

            function startAnimation() {
                line.style.width = 150 + 'px'
                parent_wrap.classList.add('swipe_animation-start')
            }
            function finallAnimation() {
                parent_wrap.classList.add('swipe_animation-final')
                line.style.width = 0 + 'px'
                document.onmousemove = null;
                btn.onmouseup = null;

                setTimeout(() => {
                    box_open(parent_wrap)
                }, 500)
                setTimeout(() => {
                    parent_wrap.classList.remove('swipe_animation-final')
                    // line.style.width=0+'px'
                }, 1500)

            }
        };
    }
}

$('.class_open').on('click', function() {
    box_open($(this));
})