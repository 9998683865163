// import './index.scss';

var createNodes = function (numNodes, radius,centre) {
    var nodes = [], 
        width = (radius * 2) ,
        height = ( radius * 2),
        angle, x, y, i;
   
    for (i=0; i<numNodes; i++) {
        angle = (i / (numNodes/2)) * Math.PI; // Calculate the angle at which the element will be placed.
                                              // For a semicircle, we would use (i / numNodes) * Math.PI.
        x = (radius * Math.cos(angle)) + centre ; // Calculate the x position of the element.
        y = (radius * Math.sin(angle))+ centre  ; // Calculate the y position of the element.
        nodes.push({'id': i, 'x': x, 'y': y});
    }
    return nodes;
  }
  
  export default function create_circle_dots(settings){
    if(!settings.class_svg){console.log('Незада область svg'); return false;}
    if(!settings.cout_circle){console.log('кількість кіл не задана'); return false;}
    if(!settings.radius_dots_max){console.log('Максимальний розмір кульки не вказано'); return false;}
    if(!settings.coef_distance){console.log('Коефіцієнт дистанції між колами не вказано'); return false;}
    if(!settings.coef_step_distance){console.log('Коефіцієнт зміни дистанції між колами не вказано'); return false;}

    var params = {};
    params.class_svg = settings.class_svg; // клас для svg куди всавляти кульки
    params.count_Balls = settings.countBalls; // кількість кульок в одному колі
    params.radius_dots_max = settings.radius_dots_max; // Найбільший розмір кульки
    params.radius_dots_min = settings.radius_dots_min; // Найменший розмір кульки
    params.coef_distance = settings.coef_distance; // коефіцієнт дистанції між колами
    params.coef_step_distance = settings.coef_step_distance; // коефіцієнт дистанції між колами
    params.cout_circle = settings.cout_circle; // кількість кіл
    params.color = settings.color || '#777'; // кількість кіл
    // console.log(document.getElementsByClassName(settings.class_svg).length );
    // console.log(document.getElementsByClassName('svg_dot_letter')  );
    // console.log(params.class_svg );
    
    // for (let index = 0; index < document.getElementsByClassName(settings.class_svg).length; index++) {
        // var svgCircle = document.getElementsByClassName(settings.class_svg)[index];
        var svgCircle = params.class_svg[0];
        var nodes = {};
        var fragment = '' ;
        // console.log(svgCircle);
        
        var radius_circle = svgCircle.clientWidth/2-4; // ширина блоку куди вставляємо svg
        var circle_centre = svgCircle.clientWidth/2; // центр кола, змінюється відносно вложеності кіл
        var circle_coeficient_up = 0;
        // console.log(radius_circle );
        var coeficient_radius_dots1 = params.radius_dots_max/(params.cout_circle* 0.65);
        var coeficient_radius_dots2 = params.radius_dots_max/ (params.cout_circle* 0.50);
        // console.log('coeficient_radius_dots1: ' + coeficient_radius_dots1 );
        // console.log('coeficient_radius_dots2: '+ coeficient_radius_dots2 );
        let radius_dots_in_circle = 0;
        for(var key = 1; key < params.cout_circle; key++){
            params.count_Balls-=2;
                
                var fragment = '' ;
                var radius_dots_glob = params.radius_dots_max ;
                var radius_dots = radius_dots_glob;
                var cof_random = 4.5; 
 
               
                 
                
            if(key < (params.cout_circle* 0.65)){
                radius_dots_in_circle= radius_dots_in_circle + coeficient_radius_dots1;
                // radius_circle -= (radius_circle * params.coef_step_distance); 
                nodes.key = createNodes(params.count_Balls, radius_circle,circle_centre);   
                radius_circle -= radius_dots_in_circle*2+8; 
                 

            }else{
                radius_dots_in_circle=radius_dots_in_circle-coeficient_radius_dots2;
                // radius_circle -= (radius_circle * (params.coef_step_distance)); 

                nodes.key = createNodes(params.count_Balls, radius_circle,circle_centre);   
                radius_circle -= (radius_dots_in_circle*2)+8; 
            }
          
            var coeficient_CIRCLE_dots = radius_dots_in_circle/ (params.count_Balls);
            // console.log('coeficient_CIRCLE_dots:' + coeficient_CIRCLE_dots );
            
            for (var i = 0; i < params.count_Balls; i++) {   
                
                if((params.count_Balls/2)>=i){
                    radius_dots_in_circle-=coeficient_CIRCLE_dots*1.1;
                }else{
                    radius_dots_in_circle+=coeficient_CIRCLE_dots;
                }
                if(radius_dots_in_circle>0){
                    fragment = fragment + '<circle r="'+radius_dots_in_circle+'" cx="' + (nodes.key[i].x ) + '" cy="' + (nodes.key[i].y ) + '" fill="'+params.color+'" class="circleBalls"/>';

                }
                // console.log(radius_dots_in_circle);
                
                // radius_dots = radius_dots * (100 * circle_coeficient_up);
                            }
            svgCircle.innerHTML += '<g>'+fragment +'</g>' ;
        }
    // }
}
  
  
function random(min, max) {
    return min + Math.random() * (max - min);
}
  
  
  