import create_circle_dots from './../../module/dot_circle/index';


// section page
import './animation_planet'
import './animation_main_blosk'
import './animation_sections_box'
import './hero_sections/index'
import './index__sections_box/index'
import './../../module/paralax_img'
import './../../module/video_motions'


let scroll_team = sessionStorage.getItem('menu_team');



; (function () {
  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".b-lazy"
    // ... more custom settings?
  });
})();

document.addEventListener("DOMContentLoaded", scrolSection);


// Через те що слайдер активовується лиш на розширенні меньше 992, 
// потрібно було динамічно міняти дата атрибут для двох 
// видів відкладеної загрузки
function switch_data_attribute(_wrap, name_data) {
  let wrapper = $(_wrap).find('.b-lazy');
  $.each(wrapper, function (index, value) {
    let src = $(value).attr('data-source');
    $(value).attr(name_data, src);
  });
}

function scrolSection() {



  if ($(window).width() < 992) {
    switch_data_attribute('.box__work', 'data-lazy');
    $('.scroll_block').on('click', function () {
      $('body,html').animate({ scrollTop: $('#section_03').offset().top }, 1000);
    })

    if (scroll_team != undefined && scroll_team == "true") {
      sessionStorage.setItem('scroll_team', '');
      setTimeout(function () {
        $('body,html').animate({ scrollTop: $('#section_03').offset().top }, 1000);
      }, 7000)
    }

    slider_work();

    $('.header__menu_box').removeClass('header__menu_box-white');
    $('.header__logo').addClass('header__logo-black');
    $(".box__team").slick({
      arrows: false,
      responsive: [
        {

          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 3,
            centerMode: true,
            lazyLoad: 'ondemand',
            // centerPadding: '20%',
            // inf
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 3,
            centerMode: true,
            lazyLoad: 'ondemand',
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            lazyLoad: 'ondemand',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            lazyLoad: 'ondemand',
          }
        },
      ]

    });

    $(".box__work").slick({
      arrows: false,
      responsive: [
        {

          breakpoint: 992,
          settings: {
            arrows: false,
            slidesToShow: 2,
            centerMode: true,
            lazyLoad: 'anticipated',
            // centerPadding: '20%',
            // inf
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            lazyLoad: 'anticipated',
          }
        },
        {
          breakpoint: 640,
          settings: {
            slidesToShow: 2,
            centerMode: true,
            lazyLoad: 'anticipated',
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            centerMode: true,
            lazyLoad: 'ondemand',
          }
        },
      ]

    });
  } else {
    switch_data_attribute('.box__work', 'data-src');
    $('.scroll_block').on('click', function () {
      fullpage_api.moveTo(3);
    })
    // console.log("scrolSection -> scroll_team", scroll_team)
    // console.log("scrolSection -> scroll_team", scroll_team == "true")
    if (scroll_team != undefined && scroll_team == "true") {

      sessionStorage.setItem('scroll_team', '');
      setTimeout(function () {
        fullpage_api.moveTo(3);
      }, 7000)
    }


    activeFullpage();

  }
}

let check_mouse_position = false;
$('.iti__country-list').on('mouseover', function (eventObject) {
  check_mouse_position = true;
});
$('.iti__country-list').on('mouseout', function (eventObject) {
  check_mouse_position = false;
});

function activeFullpage() {
  if ($('.section__wrap').length > 0) {
    new fullpage('.section__wrap', {
      autoScrolling: true,
      scrollHorizontally: true,
      responsiveWidth: 992,
      normalScrollElements: '.box__team,.box__work ',
      navigation: true,
      // scrollBar: true,
      scrollingSpeed: 1000,
      easing: ' easyInOutCubic ',
      navigationPosition: 'right',
      lockAnchors: true,
      anchors: ['main_block', 'service', 'team', 'work', 'our_response'],

      navigationTooltips: ['M', 'S', 'T', 'A', 'R'],

      afterLoad: function (origin) {

        let link_nav = document.getElementById('fp-nav').querySelectorAll('a');


        const cursor = document.getElementById('cursor');
        for (let item of link_nav) {
          item.addEventListener('mouseenter', e => {
            cursor.classList.add('is-hover')
            cursor.classList.remove('is-not_hover')
          })
          item.addEventListener('mouseleave', e => {
            cursor.classList.remove('is-hover')
            cursor.classList.add('is-not_hover')
          })
        }





      },
      afterRender: function () {
        $('.fp-right').addClass('fp-right-disble');
        // paralax('#section_01', '.main_sections_img', 'paralax_done')

        paralax('#section_01', '.bals_1', 'paralax_done', 0.2)
        paralax('#section_01', '.bals_2', 'paralax_done', 0.2)
        paralax('#section_01', '.bals_3', 'paralax_done', 0.2)


        paralax('#section_02', '.box__fix_img', 'paralax_done')
        paralax('#section_03', '.box__fix_img', 'paralax_done')
        paralax('#section_04', '.box__fix_img', 'paralax_done')
        paralax('#section_05', '.box__fix_img', 'paralax_done')



        $('#scroll_Section_01').on('click', function () { fullpage_api.moveTo(2); })
        $('#scroll_Section_02').on('click', function () { fullpage_api.moveTo(3); })
        $('#scroll_Section_03').on('click', function () { fullpage_api.moveTo(4); })
        $('#scroll_Section_04').on('click', function () { fullpage_api.moveTo(5); })
      },
      onLeave: function (origin, destination, direction) {

        if (check_mouse_position == true) {
          return false;
        }

        // if(direction == 'down' &&  destination.index == 3){
        //   return false;
        // }
        let svg_aria = $($('.section')[destination.index]).find('.svg_dot_letter');
        let svg_aria_children = svg_aria.find('g').length

        if (svg_aria.length > 0 && svg_aria_children < 1) {
          //   create_circle_dots({
          //     class_svg:svg_aria,
          //     countBalls: 60,
          //     radius_dots_max:10,
          //     coef_distance:0.04,
          //     coef_step_distance:0.05,
          //     cout_circle:10
          // }); 
        }

        if (destination.index >= 1 && destination.index <= 6) {
          $('.header__logo').addClass('header__logo-black');
          $('.fp-right').removeClass('fp-right-disble');

        } else {
          $('.header__logo').removeClass('header__logo-black');
          $('.fp-right').addClass('fp-right-disble');

        }
        // animation box

        if (direction == 'down') {
          let params = {}
          params.delay = 1500;
          params.prew_section_id = origin.item.id;
          params.next_section_id = destination.item.id;
          animation_step1(params);
        }
        if (direction == 'up') {

          let params = {}
          params.delay = 1500;
          params.prew_section_id = origin.item.id;
          params.next_section_id = destination.item.id;
          animation_step_reset1(params);
        }






      }
    });

    if (localStorage.main_anime == 'true') {
      // $('.prew__wrap').addClass('prew__wrap-none')
    } else {
      fullpage_api.setAllowScrolling(false)
      fullpage_api.setKeyboardScrolling(false)

      setTimeout(() => {
        fullpage_api.setAllowScrolling(true)
        fullpage_api.setAllowScrolling(false, 'left,right')
        fullpage_api.setKeyboardScrolling(true)
      }, 7000)
    }


  }
}





// animation
function animation_step1(params) {
  let delay = params.delay || 700;

  let next_section = $(`#${params.next_section_id}`)
  let prew_section = $(`#${params.prew_section_id}`)

  if (params.prew_section_id == "section_01") {
    $('.section_bg').addClass('section_bg-active')
    $('.section__wrap').addClass('scroll_section')
  }
  if (params.next_section_id == 'section_02') {

    next_visible(next_section);
  } else {
    $('.section__wrap').addClass('scroll_section_2');

    if (prew_section.find('.fp-tableCell')[0].classList.contains("box-open")) {
      down_open(prew_section);
    } else if (prew_section.find('.fp-tableCell')[0].classList.contains("box-close")) {
      down_close(prew_section);
    } else {
      down_visible(prew_section)
    }

  }
  if (params.next_section_id == 'section_05') {
    if (next_section.find('.fp-tableCell')[0].classList.contains("box-close-hidden")) {
      next_section.find('.fp-tableCell').removeClass('box-close-hidden').addClass('box-open_respons')
    } else {
      next_section.find('.fp-tableCell').removeClass('box-hidden').addClass('box-open_respons')
    }
  } else {
    if (next_section.find('.fp-tableCell')[0].classList.contains("box-close-hidden")) {
      next_close_visible(next_section)
    } else {
      next_visible(next_section);
    }
  }


}

function animation_step_reset1(params) {
  let prew_section = $(`#${params.prew_section_id}`)
  let next_section = $(`#${params.next_section_id}`)

  if (params.next_section_id == 'section_01') {
    $('.section__wrap').removeClass('scroll_section')
  } else {
    $('.section__wrap').addClass('scroll_section_2')
  }

  if (params.prew_section_id == 'section_05') {
    prew_section.find('.fp-tableCell').removeClass('box-open_respons').addClass('box-close-hidden')
  }

  if (params.next_section_id == 'section_04') {
  } else {
    if (prew_section.find('.fp-tableCell')[0].classList.contains("box-open")) {
      down_open(prew_section);
    } else if (prew_section.find('.fp-tableCell')[0].classList.contains("box-close")) {
      down_close(prew_section);
    } else {
      down_visible(prew_section)
    }
  }

  if (params.next_section_id == 'section_01') {
    $('.section_bg').removeClass('section_bg-active')
  } else {

    if (next_section.find('.fp-tableCell')[0].classList.contains("box-close-hidden")) {
      next_close_visible(next_section)
    } else {
      next_visible(next_section);
    }
  }
}
// рух в низ
function down_visible(sections) {
  sections.find('.fp-tableCell').removeClass('box-visible').addClass('box-hidden')
}
function down_open(sections) {
  sections.find('.fp-tableCell').removeClass('box-open').addClass('box-close-hidden')
}
function down_close(sections) {
  sections.find('.fp-tableCell').removeClass('box-close').removeClass('box-visible').addClass('box-hidden')
}

// рух в верх
function next_visible(sections) {
  sections.find('.fp-tableCell').removeClass('box-hidden').addClass('box-visible')
}
function next_close_visible(sections) {
  sections.find('.fp-tableCell').removeClass('box-close-hidden').addClass('box-visible')
}


// animation





function paralax(id_element, paralax_element, done_class, speedMove = 0.5) {
  var done_class = done_class || 'final_clas';
  var elem = $(id_element),         //    Контейнер, в котором будем проводить анимацию
    pos = elem.offset(),            //    Позиция элемента
    elem_left = pos.left,           //    Слева
    elem_top = pos.top,             //    Сверху
    elem_width = elem.width(),      //    Ширина элемента
    elem_height = elem.height(),    //    Высота элемента
    x_center,    //    Координаты центра по оси X
    y_center;    //    Координаты центра по оси Y
  $(id_element).mousemove(function (e) {
    x_center = (elem_width / 2) - (e.pageX - elem_left);
    y_center = (elem_height / 2) - (e.pageY - elem_top);
    $(paralax_element).each(function () {
      var speed = speedMove,
        xPos = -Math.round(-1 * x_center / 20 * speed),
        yPos = -Math.round(y_center / 20 * speed);
      if (id_element == '#section_01') {
        $(this).css('transform', 'translate3d(' + xPos / 2 + 'px, ' + yPos / 2 + 'px, 0px)  ');
      } else {
        $(this).find('picture').css('transform', 'translate3d(' + xPos / 3 + 'px, ' + yPos / 3 + 'px, 0px)  ');
      }
      $(this).css('transition', '.3s');
      elem.addClass(done_class);
    });
  });
}


function slider_work() {
  var slider_worc = $(".slider_work").slick({
    arrows: false,
    infinite: true,
    slidesToShow: 2,
    variableWidth: true,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,

        }
      },

    ]
  });
  slider_worc.on('afterChange', function (event, slick, currentSlide, nextSlide) {
    $('.slick-track').toggleClass('slick-track-even');
  });
  $(".slider__control_next").on('click', function () {
    slider_worc.slick('slickNext');
  });
  $(".slider__control_prew").on('click', function () {
    slider_worc.slick('slickPrev');
  });
}

var onresize = function () {
  var myNode = document.getElementsByClassName("svg_dot_letter");
  for (let key_circle_svg = 0; key_circle_svg < myNode.length; key_circle_svg++) {
    while (myNode[key_circle_svg].firstChild) {
      myNode[key_circle_svg].removeChild(myNode[key_circle_svg].firstChild);
    }
  }
  if (window.innerWidth < 992) {
    $('.header__menu_box').removeClass('header__menu_box-white');
    $('.header__logo').addClass('header__logo-black');
  } else {
    $('.header__logo').removeClass('header__logo-black');
    $('.header__menu_box ').addClass('header__menu_box-white');

    let resize_svg_area = $('.section.active').find('.svg_dot_letter');

    // create_circle_dots({
    //     class_svg:  resize_svg_area,
    //     countBalls: 60,
    //     radius_dots_max:10,
    //     coef_distance:0.04,
    //     coef_step_distance:0.05,
    //     cout_circle:10
    // });
  }

}
window.addEventListener("resize", onresize);



