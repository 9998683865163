export class BitrixIntegration {
    constructor(formId, data, form) {
      this.formId = formId;
      this.data = data;
      this.form = form;
    }
  
    parseDataForm() {
        // console.log('step 2');
      let dataForm = this.data;
      let data = {};
      for (let key of dataForm.keys()) {
        this.processedData = JSON.parse(dataForm.get("data"));
      }
    //   console.log('step 3');
    }
  
    createTitleForm() {
        // console.log('step 4');
      let title;
      let form = this.form;
      if (
        !!form.dataset.name_bitrix_form &&
        form.dataset.name_bitrix_form.length > 0
      ) {
        title = form.dataset.name_bitrix_form;
      } else {
        title = "Форма з сайту StarWay";
      }
      this.bitrixFormTitle = title;
    //   console.log('step 5');
    }
    createUrlForm() {
        // console.log('step 6');
      let url = `https://b24-84zc7u.bitrix24.ua/rest/1/0kkl5lhsd5uwrns5/crm.lead.add.json?`;
  
      let getUrlParams = `FIELDS[TITLE]=${this.bitrixFormTitle} сайт StarWay`;
      let formData = this.processedData;
      for (const key in formData) {
        if (formData.hasOwnProperty(key)) {
          const value = formData[key];
          if (key === "name" && !!value) {
            getUrlParams += `&FIELDS[NAME]=${value} `;
          } else if (key === "phone" && !!value) {
            getUrlParams += `&FIELDS[PHONE][0][VALUE]=${value}`;
          } else if (key === "email" && !!value) {
            getUrlParams += `&FIELDS[EMAIL][0][VALUE]=${value}`;
          } else if (key === "comment" && !!value) {
            getUrlParams += `&FIELDS[COMMENTS]=${value}`;
          } else {
          }
        }
      }
  
      url += `${getUrlParams}`;
  
      this.FormUrl = url;
    //   console.log('step 7');
    }
  
    sendDataAjax() {
        // console.log('step 8');
      fetch(this.FormUrl, {
        method: "GET",
      })
        .then((data) => {
          return data.json();
        })
        .then((data) => {
            // console.log('step 9');
          console.log(data);
        //   console.log('step 10');
        });
    }
  
    run() {
        // console.log('step 1');
      this.parseDataForm();

      this.createTitleForm();
      this.createUrlForm();
      this.sendDataAjax();
    }
  }