import "./basket";
import "./index.scss";
import "./dropdown.scss";
import "./../../module/modal_script/index";

setTimeout(() => {
  let vh = window.innerHeight * 0.01;
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  // // We listen to the resize event

  window.addEventListener("resize", () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });
}, 0);

$(".hamburger").on("click", function (e) {
  if ($(".hamburger").hasClass("is-active")) {
    $(".hamburger").removeClass("is-active");
    $(".header__menu").removeClass("header__menu-active");
    // $('.header__menu').removeClass('responsive__box-active');
    $("body").removeClass("body-active");
  } else {
    $(".hamburger").addClass("is-active");
    $(".header__menu").addClass("header__menu-active");
    // $('.bg-page').addClass(' bg-page-active');
    $("body").addClass(" body-active");
  }
});

$(".scroll_block").on("click", function () {
  // \

  const is_page_index = $(this).data("page_is_index");
  const url_index = $(this).data("index_url");
  // let url = window.location.pathname.split('/')[2];
  if (is_page_index === false) {
    console.log("не головна ");
    sessionStorage.setItem("menu_team", true);
    window.location.href = url_index;
  }
});

document.querySelector(".dropdown").addEventListener("click", (e) => {
  const target = e.target;
  if (target.className === "dropdown_section__title") {
    const section = target.closest(".dropdown_section");
    section.classList.toggle("active");
  }
});
