// import './index.scss'

// let processor = {
//     timerCallback: function() {
//       if (this.video.paused || this.video.ended) {
//         return;
//       }
//       this.computeFrame();
//       let self = this;
//       setTimeout(function () {
//           self.timerCallback();
//         }, 0);
//     },
  
//     doLoad: function() {
//       this.video = document.getElementById("video_main_planet");
//       this.c1 = document.getElementById("c1");
//       this.ctx1 = this.c1.getContext("2d");
//       this.c2 = document.getElementById("c2");
//       this.ctx2 = this.c2.getContext("2d");
//       let self = this;
//       console.log(self)
//       this.video.addEventListener("play", function() {
//           self.width = self.video.videoWidth / 2;
//           self.height = self.video.videoHeight / 2;
//           self.timerCallback();
//         }, false);
//     },
  
//     computeFrame: function() {
//         this.ctx1.drawImage(this.video, 0, 0, this.width, this.height);
//         let frame = this.ctx1.getImageData(0, 0, this.width, this.height);
//     console.log(frame.data );
    
//     //     let l = frame.data.length / 4;
//     // // console.log(l );
    
//     //     for (let i = 0; i < l; i++) {
//     //       let r = frame.data[i * 4 + 0];
//     //       // console.log("r", r)
//     //       let g = frame.data[i * 4 + 1];
//     //       // console.log("g", g)
//     //       let b = frame.data[i * 4 + 2];
//     //       // console.log("b", b)
//     //       // console.log("-----------" );
          
          
          

//     //       if (
//     //         (g == 235 && r == 155 && b == 69) ||
//     //         (g == 234 && r == 156 )  ||
//     //         (g == 235 && r == 149 && b == 74)  
//     //        )
//     //         frame.data[i * 4 + 3] = 0;
//         // }
//         this.ctx2.putImageData(frame, 0, 0);
//         return;
//     }
//   };

// document.addEventListener("DOMContentLoaded", () => {
//   processor.doLoad();
// });