import './index.scss'

// $('.prew__wrap').addClass('prew__wrap-active')
// $('.header').addClass('header-animation')
// $('#section_01').addClass('section-animation')


//  console.log(localStorage.main_anime);
//  console.log(localStorage.main_anime == 'true');
//  console.log('sdfdsf' );
 
if(localStorage.main_anime== 'true'){
    $('header').addClass('animatiop-minimal')
    $('.prew__wrap').addClass('prew__wrap-none')
    // console.log('asd' );
    $('#section_01').addClass('section-animation-min')
    
}else {
    localStorage.setItem('main_anime', 'true'); 
    $('.prew__wrap').addClass('prew__wrap-visible')
    $('.prew__wrap').addClass('prew__wrap-active')
    $('.header').addClass('header-animation')
    $('#section_01').addClass('section-animation')
}

 