import { instance } from "./instance";
import {
  accept_modal,
  bad_modal,
} from "./../components/module/form_action/index";

export async function addToCart(id) {
  try {
    const { data } = await instance.post("/api/cart_products/", {
      product_id: id,
    });

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function removeCartItem(id) {
  try {
    const { data } = await instance.delete(`/api/cart_product/${id}/`);

    return data;
  } catch (error) {
    console.log(error);
  }
}

export async function sendOrder(formData) {
  try {
    document.querySelector(".modal_loading__block").classList.add("active");

    const { data } = await instance.post(`/api/order_products/`, formData);

    if (data?.url) {
      accept_modal();
      document.location.href = data.url;
    }

    return data;
  } catch (error) {
    console.log(error);
    bad_modal(error);
  }
}
